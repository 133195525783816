import { Facet } from '@ui/helpers/filter';

export function getFacetsMapping(facets: Facet[]) {
  const facetsToDisplay = ['Size', 'Color', 'Price', 'Length', 'Sleeves', 'Trend', 'Occasion', 'Brand'];
  const findFacetByLabel = (label: string) => facets.filter((facet) => facet.label === label)[0];
  return facetsToDisplay.map((key) => findFacetByLabel(key) || { label: key, values: [] });
}

export function getFacetLabel(label: string) {
  if (label.toLowerCase() === 'color') {
    return 'Colour';
  } else {
    return label;
  }
}
