import React from 'react';
import ColorSwatch from '../../visualization/ColorSwatch/ColorSwatch';
import cn from '@ui/utils/cn';

type ToggleProps = React.HTMLAttributes<HTMLElement> & {
  pressed?: Boolean;
  onPressedChange?: (pressed: boolean) => void;
  variant?: 'outline' | 'swatch' | 'radio';
  value?: string;
  asChild?: boolean;
  component?: any;
};

const Toggle = React.forwardRef<HTMLElement, ToggleProps>(
  (
    {
      component: Comp = 'button',
      children,
      className,
      onPressedChange,
      pressed = false,
      value = '',
      variant = 'radio',
      ...props
    },
    ref,
  ) => {
    return (
      <Comp
        ref={ref}
        data-state={pressed ? 'on' : 'off'}
        onClick={() => onPressedChange?.(!pressed)}
        className={cn(
          variant === 'outline' &&
            cn(
              'rounded-sm w-full h-8 text-[10px] text-center uppercase transition',
              'flex items-center justify-center',
              'border border-black border-solid',
              'data-[state=on]:bg-black data-[state=on]:text-white',
            ),
          variant === 'swatch' && 'flex items-center space-x-4 text-[13px] text-left capitalize group',
          variant === 'radio' && 'flex items-center space-x-4 text-[13px] text-left capitalize group',
          className,
        )}
        {...props}
      >
        {variant === 'swatch' && (
          <div className="shrink-0 rounded-full w-4 h-4 border border-black bg-white group-data-[state=on]:p-0.5">
            <ColorSwatch w="100%" h="100%" color={value} />
          </div>
        )}

        {variant === 'radio' && (
          <div className="shrink-0 rounded-full w-4 h-4 border border-black p-0.5">
            <div className="rounded-full w-full h-full bg-white group-data-[state=on]:bg-[#EA98B8] md:group-hover:bg-[#F4D4E2]" />
          </div>
        )}

        <span className="flex-1 md:group-hover:text-black/60">{children}</span>
      </Comp>
    );
  },
);

Toggle.displayName = 'Toggle';

export default Toggle;
